<template>
  <div class="container-fluid py-3 my-2" id="ctsHeader">
    <div class="row">
      <div class="wrapper">
        <router-link :to="{ name: 'ATravesDeLaNutricion' }" class="item">
          
            <img
              src="@/assets/images/cuidando-tu-salud/01.jpg"
              class="img-fluid"
            />
            <div class="titulo p-3">
              <h6>{{ $t("cuidandoTuSalud.aTravesDeLaNutricion.title") }}</h6>
            </div>
        </router-link>
        <router-link :to="{ name: 'VidaSaludable' }" class="item hhh" >
            <img
              src="@/assets/images/cuidando-tu-salud/02.jpg"
              class="img-fluid"
            />
            <div class="titulo p-3">
              <h6>{{ $t("cuidandoTuSalud.vidaSaludable.title") }}</h6>
            </div>
        </router-link>
        <router-link :to="{ name: 'EnvejeciendoBien' }" class="item hhh" >
            <img
              src="@/assets/images/cuidando-tu-salud/03.jpg"
              class="img-fluid"
            />
            <div class="titulo p-3">
              <h6>{{ $t("cuidandoTuSalud.envejeciendoBien.title") }}</h6>
            </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
};
</script>

