<template>
  <div id="cuidandoTuSalud">
    <Header />

    <div class="container-fluid bg-gris-claro pt-4 pb-5">
      <div class="container">
        <section>
          <h5>{{ $t("cuidandoTuSalud.aTravesDeLaNutricion.title") }}</h5>
          <ul class="fa-ul">
            <li class="option"><span class="fa-li"><i class="fa fa-circle-o"></i></span>
              <router-link :to="{name: 'ATravesDeLaNutricion', hash: '#los-alimentos-como-la-base-del-sistema-inmunitario'}">
              {{ $t('cuidandoTuSalud.aTravesDeLaNutricion.a') }}
              </router-link>
            </li>
            <li class="option"><span class="fa-li"><i class="fa fa-circle-o"></i></span>
              <router-link :to="{name: 'ATravesDeLaNutricion', hash: '#azucar-en-sangre-los-alimentos-aliados'}">
              {{ $t('cuidandoTuSalud.aTravesDeLaNutricion.b') }}
              </router-link>
            </li>
            <li class="option"><span class="fa-li"><i class="fa fa-circle-o"></i></span>
              <router-link :to="{name: 'ATravesDeLaNutricion', hash: '#preparacion-y-recuperacion-de-cirugias'}">
              {{ $t('cuidandoTuSalud.aTravesDeLaNutricion.c',) }}
              </router-link>
            </li>
            <li class="option"><span class="fa-li"><i class="fa fa-circle-o"></i></span>
              <router-link :to="{name: 'ATravesDeLaNutricion', hash: '#desnutricion'}">
              {{ $t('cuidandoTuSalud.aTravesDeLaNutricion.d') }}
              </router-link>
            </li>
            <li class="option"><span class="fa-li"><i class="fa fa-circle-o"></i></span>
              <router-link :to="{name: 'ATravesDeLaNutricion', hash: '#alergia-a-la-proteína-de-leche-e-intolerancia-a-la-lactosa'}">
              {{ $t('cuidandoTuSalud.aTravesDeLaNutricion.e') }}
              </router-link>
            </li>
            <li class="option"><span class="fa-li"><i class="fa fa-circle-o"></i></span>
              <router-link :to="{name: 'ATravesDeLaNutricion', hash: '#epilepsia-refractaria'}">
              {{ $t('cuidandoTuSalud.aTravesDeLaNutricion.f') }}
              </router-link>
            </li>
          </ul>
        </section>
        <section>
          <h5>{{ $t("cuidandoTuSalud.vidaSaludable.title") }}</h5>
           <ul class="fa-ul">
            <li class="option"><span class="fa-li"><i class="fa fa-circle-o"></i></span>
              <router-link :to="{name: 'VidaSaludable', hash: '#como-elegir-los-hidratos-de-carbono'}">
              {{ $t('cuidandoTuSalud.vidaSaludable.a') }}
              </router-link>
            </li>
            <li class="option"><span class="fa-li"><i class="fa fa-circle-o"></i></span>
              <router-link :to="{name: 'VidaSaludable', hash: '#hidratacion-deporte-y-salud'}">
              {{ $t('cuidandoTuSalud.vidaSaludable.b') }}
              </router-link>
            </li>
            <li class="option"><span class="fa-li"><i class="fa fa-circle-o"></i></span>
              <router-link :to="{name: 'VidaSaludable', hash: '#cuanta-azucar-es-saludable'}">
              {{ $t('cuidandoTuSalud.vidaSaludable.c') }}
              </router-link>
            </li>
            <li class="option"><span class="fa-li"><i class="fa fa-circle-o"></i></span>
              <router-link :to="{name: 'VidaSaludable', hash: '#snacks-previo-entrenamiento'}">
              {{ $t('cuidandoTuSalud.vidaSaludable.d') }}
              </router-link>
            </li>
           </ul>
        </section>
        <section>
          <h5>{{ $t("cuidandoTuSalud.envejeciendoBien.title") }}</h5>
          <ul class="fa-ul">
            <li class="option"><span class="fa-li"><i class="fa fa-circle-o"></i></span>
              <router-link :to="{name: 'EnvejeciendoBien', hash: '#musculos-y-proteinas'}">
              {{ $t('cuidandoTuSalud.envejeciendoBien.a') }}
              </router-link>
            </li>
            <li class="option"><span class="fa-li"><i class="fa fa-circle-o"></i></span>
              <router-link :to="{name: 'EnvejeciendoBien', hash: '#neuronutrientes'}">
              {{ $t('cuidandoTuSalud.envejeciendoBien.b') }}
              </router-link>
            </li>
          </ul>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/views/modules/CTS_Header.vue";

export default {
  name: "CuidandoTuSalud",
  components: {
    Header,
  },
  mounted () {
  window.scrollTo(0, 0)
}
};
</script>